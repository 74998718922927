import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.scss"
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Pinverify from './Components/pinverify';
import Home from './App';
import TokenVerify from './Components/tokenVerify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route Component={Home} path='/'></Route>
        <Route Component={Pinverify} path='/pinverify'></Route>
        <Route Component={TokenVerify} path='/tokenverify'></Route>
      </Routes>
    </Router>
  </React.StrictMode>
);


