import React, { useState, useEffect } from 'react';

function Clock() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(new Date());
    };

    const timer = setInterval(updateDateTime, 60000);

    return () => clearInterval(timer);
  }, []);

  const formattedDateTime = () => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    const formattedDate = currentDateTime.toLocaleString('en-US', options).replace(/\//g, '-');

    const [date, time] = formattedDate.split(', ');

    const [mm, dd, yyyy] = date.split('-');

    return `${yyyy}-${mm}-${dd} ${time}`;
  };

  return (
    <div style={{ fontSize: '11px', color: 'white', fontWeight: "bold" }}>
      <p>{formattedDateTime()}</p>
    </div>
  );
}

export default Clock;