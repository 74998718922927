import React from 'react';
import styles from "./Navigation.module.scss"

function Navigation() {
  return (
    <nav className={styles.nav}>
        <a href=".">Home</a>
        <span className={styles.span}>|</span>
        <a href="https://emgr.efsllc.com/common/contactInformation.pdf" target='_blank'>Help</a>
        <span className={styles.span}>|</span>
        <a href=".">Login</a>
    </nav>
  );
}

export default Navigation;