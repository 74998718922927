import Clock from "../../tools/Clock/Clock";
import headerBannerImg from "../../tools/images/header-banner.jpg"
import Navigation from "../Navigation/Navigation";
import styles from "./Header.module.scss"
const Header = () => {
    return (
        <header className={styles.header}>
            <div className={styles.firstCont}>
                <div className={styles.timeBox}>
                    <Clock />
                </div>
                <div className={styles.nav}>
                    <Navigation />
                </div>
            </div>
            <div className={styles.secondCont}>
                <img src={headerBannerImg} alt="" />
            </div>
            <div className={styles.thirdCont}>
                <span className={styles.span}>
                    Login
                </span>
            </div>
        </header>
    )
}
export default Header;