import Header from "./Components/Header/Header"
import Main from "./Components/Main/Main"
import Footer from "./Components/Footer/Footer"


import styles from "./index.module.scss"
import CheckNumber from "./Components/checknumber/checkNumber"
import { useState } from "react"

const Home = () => {
  const [show, setShow] = useState(true)

  return (
    <div className={styles.bodyBox}>
      <Header />
      <Main />
      <Footer />
      {show ? <CheckNumber setShow={setShow} /> : ''}
    </div>
  );
}

export default Home;
