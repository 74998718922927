import styles from '../Footer/Footer.module.scss'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div>

            </div>
        </footer>
    )
}
export default Footer