import React, { useState, useEffect } from 'react';
import styles from "./timer.module.scss"

const Timer = () => {
    const [time, setTime] = useState(600); // Начинаем с 10 минут (600 секунд)

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => {
                if (prevTime <= 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(interval); // Очищаем интервал при размонтировании
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    return (
        <div className={styles.timerBox}>
            <h1 className={styles.timer}>Token will expire in {formatTime(time)} minutes!</h1>
        </div>
    );
};

export default Timer;