import React from "react";
import styles from "../Header/PinHeader.module.scss"
import Clock from "../../../tools/Clock/Clock";
import Navigation from "../../Navigation/Navigation";
import efsLogo from "../../../tools/images/efs.png"

const PinHeader = ({name}) => {
    return (
        <header className={styles.headerCont}>
            <div className={styles.firstCont}>
                <div className={styles.timeBox}>
                    <Clock />
                </div>
                <div className={styles.nav}>
                    <Navigation />
                </div>
            </div>
            <img src={efsLogo} alt="" />
            <div className={styles.thirdCont}>
                <span className={styles.span}>
                    {name}
                </span>
            </div>
        </header>

    )
}

export default PinHeader;